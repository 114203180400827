import { Component } from 'react';
import moment from 'moment';
import CryptoJS from 'crypto-js';

import { getVerifyTicket } from 'api';

class QueryHandler extends Component {

  async componentDidMount() {
    const ticket = window.location.search.substring(8);
    const verifyTicketResult = await getVerifyTicket(ticket);
    
    // 비밀번호확인티켓이 유효한 경우
    if (verifyTicketResult.header.isSuccessful) {
      const confirmTimestamp = moment().format('YYYYMMDDHHmmss'); // Timestamp
      const encTimestamp = CryptoJS.AES.encrypt(confirmTimestamp, 'arts').toString(); // Timestamp 암호화

      sessionStorage.setItem('confirm_byte', encTimestamp);
    }
    else {
      alert('비정상적인 접근입니다.');
    }

    window.top.location.replace('/club-artisee/my-page/');
  }

  render() {
    return null;
  }
}

export default QueryHandler;